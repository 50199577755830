var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Dropdown", {
    ref: "filterdropdown",
    on: { hide: _vm.setSelection },
    scopedSlots: _vm._u(
      [
        {
          key: "trigger",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "filterdropdown-trigger" },
                [
                  _vm._v(" " + _vm._s(_vm.filter.title) + " "),
                  _vm.selected.length
                    ? _c("Tag", {
                        staticClass: "filterdropdown-trigger-count",
                        attrs: {
                          text: "" + _vm.selected.length,
                          type: "light-solid",
                          size: "xxs"
                        }
                      })
                    : _vm._e(),
                  _c("img", {
                    staticClass: "filterdropdown-trigger-chevron",
                    attrs: {
                      src: require("@/assets/icons/chevron-down-medium.svg"),
                      alt: ""
                    }
                  })
                ],
                1
              )
            ]
          },
          proxy: true
        },
        {
          key: "content",
          fn: function() {
            return [
              _c("div", { staticClass: "filterdropdown-content" }, [
                _vm.filter.options.length > 10
                  ? _c(
                      "div",
                      { staticClass: "filterdropdown-content-header" },
                      [
                        _c("TextInput", {
                          attrs: {
                            placeholder:
                              "Find " + _vm.filter.title.toLowerCase()
                          },
                          model: {
                            value: _vm.query,
                            callback: function($$v) {
                              _vm.query = $$v
                            },
                            expression: "query"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    staticClass: "filterdropdown-content-items",
                    style: "--dropdown-content-height: " + _vm.maxHeight + ";"
                  },
                  _vm._l(_vm.filteredOptions, function(option, idx) {
                    return _c(
                      "div",
                      {
                        key: _vm.filter.title + "-option-" + idx,
                        staticClass: "filterdropdown-content-items-item",
                        class: {
                          selected: _vm.selection.includes(
                            option.id || option.key
                          ),
                          disabled:
                            _vm.selection.length &&
                            !_vm.selection.includes(option.key) &&
                            !_vm.filter.multiselect
                        },
                        attrs: {
                          title: option.value || option.key || _vm.emptyText
                        },
                        on: {
                          click: function() {
                            return _vm.selectOption(option.id || option.key)
                          }
                        }
                      },
                      [
                        _c("Checkbox", {
                          staticClass:
                            "filterdropdown-content-items-item-checkbox",
                          attrs: {
                            value: _vm.selection.includes(
                              option.id || option.key
                            )
                          }
                        }),
                        _vm._t(
                          "item",
                          function() {
                            return [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "filterdropdown-content-items-item-text"
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.filter.capitalize
                                        ? (
                                            option.value ||
                                            option.key ||
                                            _vm.emptyText
                                          ).capitalize()
                                        : option.value ||
                                            option.key ||
                                            _vm.emptyText
                                    )
                                  )
                                ]
                              )
                            ]
                          },
                          { item: option }
                        ),
                        option.count
                          ? _c("Tag", {
                              staticClass:
                                "filterdropdown-content-items-item-count",
                              attrs: {
                                text: "" + option.count,
                                type: "light-solid",
                                size: "xxs"
                              }
                            })
                          : _vm._e()
                      ],
                      2
                    )
                  }),
                  0
                ),
                _c(
                  "div",
                  { staticClass: "filterdropdown-content-footer" },
                  [
                    _c("Button", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selection.length,
                          expression: "selection.length"
                        }
                      ],
                      attrs: { text: "Clear", type: "light" },
                      on: { click: _vm.handleClear }
                    }),
                    _c("Button", {
                      staticClass: "filterdropdown-content-footer-apply",
                      attrs: { text: "Apply" },
                      on: { click: _vm.submit }
                    })
                  ],
                  1
                )
              ])
            ]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }