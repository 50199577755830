<template>
  <Dropdown ref="filterdropdown" @hide="setSelection">
    <template #trigger>
      <div class="filterdropdown-trigger">
        {{ filter.title }}
        <Tag
          v-if="selected.length"
          :text="`${selected.length}`"
          type="light-solid"
          size="xxs"
          class="filterdropdown-trigger-count"
        />
        <img
          src="@/assets/icons/chevron-down-medium.svg"
          alt=""
          class="filterdropdown-trigger-chevron"
        />
      </div>
    </template>
    <template #content>
      <div class="filterdropdown-content">
        <div
          v-if="filter.options.length > 10"
          class="filterdropdown-content-header"
        >
          <TextInput
            v-model="query"
            :placeholder="`Find ${filter.title.toLowerCase()}`"
          />
        </div>
        <div
          class="filterdropdown-content-items"
          :style="`--dropdown-content-height: ${maxHeight};`"
        >
          <div
            v-for="(option, idx) in filteredOptions"
            :key="`${filter.title}-option-${idx}`"
            class="filterdropdown-content-items-item"
            :class="{
              selected: selection.includes(option.id || option.key),
              disabled:
                selection.length &&
                !selection.includes(option.key) &&
                !filter.multiselect
            }"
            :title="option.value || option.key || emptyText"
            @click="() => selectOption(option.id || option.key)"
          >
            <Checkbox
              :value="selection.includes(option.id || option.key)"
              class="filterdropdown-content-items-item-checkbox"
            />
            <slot name="item" :item="option">
              <span class="filterdropdown-content-items-item-text">{{
                filter.capitalize
                  ? (option.value || option.key || emptyText).capitalize()
                  : option.value || option.key || emptyText
              }}</span>
            </slot>
            <Tag
              v-if="option.count"
              :text="`${option.count}`"
              type="light-solid"
              size="xxs"
              class="filterdropdown-content-items-item-count"
            />
          </div>
        </div>
        <div class="filterdropdown-content-footer">
          <Button
            v-show="selection.length"
            text="Clear"
            type="light"
            @click="handleClear"
          />
          <Button
            text="Apply"
            class="filterdropdown-content-footer-apply"
            @click="submit"
          />
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script>
import Button from './Button.vue'
import Dropdown from './Dropdown.vue'
import Checkbox from './Checkbox.vue'
import Tag from './Tag.vue'
import TextInput from './TextInput.vue'

export default {
  name: 'FilterDropdown',
  components: {
    Dropdown,
    Button,
    Checkbox,
    Tag,
    TextInput
  },
  props: {
    filter: {
      type: Object,
      default: () => ({})
    },
    selected: {
      type: Array,
      default: () => []
    },
    maxHeight: {
      type: String,
      default: '50vh'
    },
    emptyText: {
      type: String,
      default: ''
    },
    clearSubmits: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    selection: [],
    query: ''
  }),
  computed: {
    filteredOptions() {
      return this.filter.options.filter((option) =>
        typeof option.value === 'string'
          ? option?.value?.toLowerCase()?.includes(this.query.toLowerCase())
          : typeof option.key === 'string'
          ? option?.key?.toLowerCase()?.includes(this.query.toLowerCase())
          : this.emptyText.toLowerCase().includes(this.query.toLowerCase())
      )
    }
  },
  watch: {
    selected() {
      this.setSelection()
    }
  },
  created() {
    this.setSelection()
  },
  methods: {
    setSelection() {
      this.selection = [...this.selected]
    },
    selectOption(option) {
      if (this.selection.includes(option)) {
        this.selection = this.selection.filter((item) => item !== option)
      } else {
        this.selection.push(option)
      }
    },
    handleClear() {
      this.selection = []
      if (this.clearSubmits) {
        this.submit()
      }
    },
    submit() {
      this.$emit('submit', this.selection)
      this.$refs.filterdropdown.hideOptions()
    }
  }
}
</script>

<style lang="scss" scoped>
.filterdropdown {
  &-trigger {
    min-width: 10rem;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0.75rem;
    border-radius: 4px;
    border: 1px solid rgba(#000, 0.08);
    user-select: none;

    &-count {
      padding: 0 0.5rem;
    }

    &-chevron {
      height: 1.2rem;
      margin-left: auto;
    }
  }

  &-content {
    background: white;
    border-radius: 4px;
    border: 1px solid rgba(#000, 0.08);
    width: 20rem;
    padding-top: 0.5rem;
    user-select: none;

    &-header {
      padding: 0.5rem 0.75rem;
    }

    &-items {
      max-height: var(--dropdown-content-height);
      overflow-y: auto;

      &-item {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 0.5rem 0.75rem;
        gap: 1rem;
        cursor: pointer;
        user-select: none;

        &.selected {
          background: rgba(#000, 0.08);
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.5;
        }

        &:hover {
          background: rgba(#000, 0.04);
        }

        &-checkbox {
          pointer-events: none;
        }

        &-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &-count {
          margin-left: auto;
        }
      }
    }

    &-footer {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem 0.75rem;
      border-top: 1px solid rgba(#000, 0.08);

      &-apply {
        margin-left: auto;
      }
    }
  }
}
</style>
